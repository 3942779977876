/**
 * Created by guanyj on  2019-08-27
 */


export const ChartStyle = {
    color: ["#3ea0ff", "#52cb74", "#fbd339", "#f04864", "#975be5", "#3fcbcb"],
    grid: {
        top: '10%',
        left: '80px',
        bottom: '10%',
        right: '5%',
        containLabel: true
    }
};

// 通用线型图标基础配置【待完善】
export const ChartOfLineOptions: any = {
    title: {
        text: '',
        subtext: '',
        sublink: '',
        textAlign: 'auto',
        left: 'center',
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        textStyle: {
            fontSize: 20
        },
        padding: 5,
        itemGap: 10,
        show: true,
    },
    backgroundColor: '#eee',
    color: ChartStyle.color,
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        icon: 'roundRect',
        type: 'plain',
        left: 'center',
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        orient: 'horizontal',

        data: []
    },
    grid: ChartStyle.grid,
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: []
};
