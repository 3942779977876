/**
 * Created by guanyj on  7/17/19
 */
import {Injectable} from "@angular/core";
import _ from 'lodash';
import {StorageService} from "@orchid_service/storage";

@Injectable()
export class DictionaryService {

    /**
     * 字典配置集合
     */
    private readonly dictionaries: any;

    constructor(
        private $storage: StorageService
    ) {
        this.dictionaries = this.$storage.get('dictionary') || {};
    }


    /**
     * 由字典码查询字典集合
     * @param code
     */
    getDictionaryByCode(code: any): Array<{value: any, label: string, pid?: any}> {
        if (_.isEmpty(this.dictionaries)) {
            throw new Error('数据字典未能正确初始化');
        }
        if (!this.dictionaries.hasOwnProperty(code)) {
            throw new Error('未定义的字典Code:' + code);
        }
        const list = this.dictionaries[code];
        if (list) {
            return list.map(item => ({value: item.id, label: item.nodeName, pid: item.pid}));
        }
        return [];
    }

}
