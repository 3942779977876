/**
 * Created by guanyj on  7/23/19
 * @desc 图片缺省占位符
 */
import {Directive, ElementRef, HostListener, Input} from "@angular/core";

@Directive({
    selector: '[pmsImageDefault]'
})
export class ImageDefaultDirective {

    @Input() defaultHeight: number;
    @Input() defaultWidth: number;

    constructor(
        private $el: ElementRef
    ) {

    }

    @HostListener('error', ['$event.target'])
    onError(target: any) {
        target.src = 'https://ovft-public-pictures.oss-cn-hangzhou.aliyuncs.com/static-no-pic-190723.jpg';
        if (this.defaultWidth) {
            target.width = this.defaultWidth;
        }
        if (this.defaultHeight) {
            target.height = this.defaultHeight;
        }
    }

}
