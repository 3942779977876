/**
 * Created by guanyj on  12/17/19
 * @desc 文本超出显示省略号
 */
import {AfterViewInit, Directive, ElementRef} from "@angular/core";

@Directive({
    selector: '[pmsEllipsis]',
})
export class EllipsisDirective implements AfterViewInit {

    constructor(
        private $el: ElementRef
    ) {}

    ngAfterViewInit(): void {
        this.$el.nativeElement.style.overflow = 'hidden';
        this.$el.nativeElement.style.textOverflow = 'ellipsis';
        this.$el.nativeElement.style.whiteSpace = 'nowrap';
    }
}
