/**
 * Created by guanyj on  12/9/19
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiPasturelandService implements ITpiMenu {

    generateMenuItems(): Array<TpiMenuItem> {
        const menuItems = [];

        const pasturelandMenuItem = new TpiMenuItem();
        pasturelandMenuItem.id = 200000;
        pasturelandMenuItem.name = '牧场管理';
        pasturelandMenuItem.path = '/ranch/pastureland';
        pasturelandMenuItem.accessId = '1010';
        menuItems.push(pasturelandMenuItem);

        const operationMenuItem = new TpiMenuItem();
        operationMenuItem.id = 201000;
        operationMenuItem.name = '业务操作';
        operationMenuItem.isHide = true;
        operationMenuItem.path = '/ranch/pastureland/operation';
        operationMenuItem.parentId = pasturelandMenuItem.id;
        menuItems.push(operationMenuItem);

        const statisticsMenuItem = new TpiMenuItem();
        statisticsMenuItem.id = 201001;
        statisticsMenuItem.name = '牧场统计';
        statisticsMenuItem.icon = 'tongji';
        statisticsMenuItem.path = '/ranch/pastureland/operation/statistics';
        statisticsMenuItem.accessId = '101013';
        statisticsMenuItem.backId = pasturelandMenuItem.id;
        statisticsMenuItem.parentId = operationMenuItem.id;
        menuItems.push(statisticsMenuItem);

        const oprManageMenuItem = new TpiMenuItem();
        oprManageMenuItem.id = 201002;
        oprManageMenuItem.name = '在栏管理';
        oprManageMenuItem.icon = 'muchang';
        oprManageMenuItem.path = '/ranch/pastureland/operation/livestock';
        oprManageMenuItem.accessId = '101010';
        oprManageMenuItem.backId = pasturelandMenuItem.id;
        operationMenuItem.pathParams = {
            queryParamsHandling: 'merge'
        };
        oprManageMenuItem.parentId = operationMenuItem.id;
        menuItems.push(oprManageMenuItem);

        const oprFacilityMenuItem = new TpiMenuItem();
        oprFacilityMenuItem.id = 201003;
        oprFacilityMenuItem.name = '牧场设备';
        oprFacilityMenuItem.icon = 'gongcheng';
        oprFacilityMenuItem.path = '/ranch/pastureland/operation/facility';
        oprFacilityMenuItem.accessId = '101011';
        oprFacilityMenuItem.backId = pasturelandMenuItem.id;
        oprFacilityMenuItem.parentId = operationMenuItem.id;
        menuItems.push(oprFacilityMenuItem);

        const oprMonitorMenuItem = new TpiMenuItem();
        oprMonitorMenuItem.id = 201101;
        oprMonitorMenuItem.name = '监控设备';
        oprMonitorMenuItem.icon = 'shexiangtou';
        oprMonitorMenuItem.path = '/ranch/pastureland/operation/facility/monitor';
        oprMonitorMenuItem.accessId = '10101101';
        oprMonitorMenuItem.backId = operationMenuItem.id;
        oprMonitorMenuItem.parentId = oprFacilityMenuItem.id;
        menuItems.push(oprMonitorMenuItem);

        const oprEarTagMenuItem = new TpiMenuItem();
        oprEarTagMenuItem.id = 201102;
        oprEarTagMenuItem.name = '耳标设备';
        oprEarTagMenuItem.icon = 'anniu_erbiao';
        oprEarTagMenuItem.path = '/ranch/pastureland/operation/facility/eartag';
        oprEarTagMenuItem.accessId = '10101102';
        oprEarTagMenuItem.backId = operationMenuItem.id;
        oprEarTagMenuItem.parentId = oprFacilityMenuItem.id;
        menuItems.push(oprEarTagMenuItem);

        const oprSettingMenuItem = new TpiMenuItem();
        oprSettingMenuItem.id = 201004;
        oprSettingMenuItem.name = '配置参数';
        oprSettingMenuItem.icon = 'setting';
        oprSettingMenuItem.path = '/ranch/pastureland/operation/setting';
        oprSettingMenuItem.accessId = '101012';
        oprSettingMenuItem.backId = pasturelandMenuItem.id;
        oprSettingMenuItem.parentId = operationMenuItem.id;
        menuItems.push(oprSettingMenuItem);

        return menuItems;
    }

}
