/**
 * Created by guanyj on  12/9/19
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiReceiptsService implements ITpiMenu {

    generateMenuItems(): Array<TpiMenuItem> {
        const menuItems = [];

        const receiptsMenuItem = new TpiMenuItem();
        receiptsMenuItem.id = 500000;
        receiptsMenuItem.name = '业务单据';
        receiptsMenuItem.path = '/ranch/receipts';
        receiptsMenuItem.accessId = '1014';
        menuItems.push(receiptsMenuItem);

        return menuItems;
    }

}
