/**
 * Created by guanyj on  12/14/19
 * @desc 表格跨页勾选组件。
 */
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {GridChangeEvent, GridColumns, GridModel, IBaseGrid} from "@orchid_component/grid";

@Component({
    selector: 'pms-grid-selection-model',
    templateUrl: './grid-selection-modal.component.html',
    styleUrls: ['./grid-selection-modal.component.scss']
})
export class GridSelectionModalComponent implements IBaseGrid, OnChanges {

    @Input() selectedRows: Array<any> = [];
    @Input() columns: GridColumns[];
    queryParams: GridChangeEvent = new GridChangeEvent();
    viewData: GridModel = new GridModel<any>();

    visible = false;

    @Output() clear = new EventEmitter();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedRows && changes.selectedRows.currentValue) {
            const value = changes.selectedRows.currentValue;
            if (Array.isArray(value)) {
                this.loadGridDataSource();
            }
        }
    }

    initColumnsSetting(): void {
    }

    loadGridDataSource(): void {
        const offset = this.queryParams.pager.pageSize * (this.queryParams.pager.pageNo - 1);
        const model = new GridModel();
        model.records = this.selectedRows.slice(offset, Math.min(this.selectedRows.length, offset + this.queryParams.pager.pageSize));
        model.totalCount = this.selectedRows.length;
        this.viewData = model;
    }

    onDataSourceChange(event: GridChangeEvent): void {
        this.queryParams = event;
        this.loadGridDataSource();
    }

    onCancel() {
        this.visible = false;
    }

    handleClear() {
        this.onCancel();
        this.clear.emit();
    }
}
