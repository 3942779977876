/**
 * Created by guanyj on  12/9/19
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiPortalService implements ITpiMenu {

    generateMenuItems(): Array<TpiMenuItem> {
        const menuItems = [];

        const portalMenuItem = new TpiMenuItem();
        portalMenuItem.id = 100000;
        portalMenuItem.name = '首页';
        portalMenuItem.path = '/ranch/portal';
        menuItems.push(portalMenuItem);

        return menuItems;
    }

}
