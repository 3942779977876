/**
 * Created by guanyj on  10/25/19
 */
import {Pipe, PipeTransform} from "@angular/core";
import {DictionaryService} from "../service/dictionary/dictionary.service";

@Pipe({
    name: 'dictVT'
})
export class DictionaryVtFilter implements PipeTransform {

    constructor(
        private $dic: DictionaryService
    ) {}

    transform(value: any, dictName: string): any {
        const list = this.$dic.getDictionaryByCode(dictName);
        if (!list) {
            return "-";
        }
        console.log(list);
        const target = list.find(item => item.value === value);
        return target && target.label || '-';
    }

}
