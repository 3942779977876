/**
 * Created by guanyj on  2019-08-08
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiFacilityService implements ITpiMenu {

    generateMenuItems(): Array<TpiMenuItem> {
        const menus: TpiMenuItem[] = [];

        const portalMenuItem = new TpiMenuItem();
        portalMenuItem.id = 600000;
        portalMenuItem.name = '设备资产';
        portalMenuItem.path = '/ranch/facility';
        portalMenuItem.accessId = '1012';
        menus.push(portalMenuItem);

        return menus;
    }

}
