/**
 * Created by guanyj on  2019-08-27
 */
import {Component, Input, OnInit} from "@angular/core";
import {ChartCustomInterface} from "./chart-custom.interface";
import {ActivatedRoute} from "@angular/router";
import {ChartStyle} from "../../const/chart-style.constant";

@Component({
    selector: 'pms-chart-by-custom',
    templateUrl: './chart-by-custom.component.html',
    styleUrls: ['./chart-by-custom.component.scss']
})
export class ChartByCustomComponent implements OnInit {

    @Input() rankTitle: string;

    rankList = [];
    chartOptions;

    // 结果返回元数据
    sourceData;

    cardLoading = false;

    currentStatType = 1;
    currentStatUnit = 1;
    btnList = [];

    defaultData = new Date();

    selectedYear = new Date();
    selectedMonth = this.selectedYear.getMonth() + 1;

    constructor(
        private $portal: ChartCustomInterface,
        private $active: ActivatedRoute
    ) {
        this.btnList = [
            {value: 1, label: '入栏量'},
            {value: 2, label: '出栏量'},
            {value: 3, label: '在栏量'}
        ];
    }

    ngOnInit(): void {
        this.loadChartDataSource();
    }

    loadChartDataSource() {
        this.cardLoading = true;
        this.rankList = [];
        this.$portal.queryStatByCustom({
            ranchId: this.$active.snapshot.queryParams.id,
            month: this.selectedMonth,
            year: this.selectedYear.getFullYear(),
            type: this.currentStatType
        }).subscribe(result => {
            this.sourceData = result.data;

            this.updateChart();

            this.cardLoading = false;
        }, (error) => {
            this.cardLoading = false;
        });
    }

    /**
     * 切换统计类型
     * @param event
     */
    onStatTypeChange(event: number) {
        this.currentStatType = event;

        this.loadChartDataSource();
    }

    /**
     * 年份选择器发生变化
     * @param event
     */
    onYearChange(event) {
        this.selectedYear = event;
        // 触发查询
        this.loadChartDataSource();
    }

    /**
     * 月份选择器发生变化
     * @param event
     */
    onMonthChange(event) {
        this.selectedMonth = event;
        this.loadChartDataSource();
    }

    private updateChart() {
        let chartNodes, seriesTitle;
        if (this.currentStatType === 1) {
            seriesTitle = '入栏量';
        } else if (this.currentStatType === 2) {
            seriesTitle = '出栏量';
        } else {
            seriesTitle = '在栏量';
        }
        chartNodes = this.sourceData.months;
        this.rankList = this.sourceData.stalls;

        this.chartOptions = {
            color: ChartStyle.color,
            grid: ChartStyle.grid,
            xAxis: [
                {
                    type: 'category',
                    data: chartNodes.map(item => item.key)
                }
            ],
            tooltip: {
                show: true,
                formatter: '{a0}: {c0}头'
            },
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: {
                name: seriesTitle,
                type: 'bar',
                // barWidth: '80%',
                barMaxWidth: '64px',
                data: chartNodes.map(item => item.value)
            }
        };
    }
}
