/**
 * Created by guanyj on  12/9/19
 */
import {NgModule} from "@angular/core";
import {TpiPortalService} from "./service/tpi-menu/tpi-portal.service";
import {TpiPasturelandService} from "./service/tpi-menu/tpi-pastureland.service";
import {EventModule} from "@orchid_service/event";
import {OrchidGridModule} from "@orchid_component/grid";
import {OrchidIconModule} from "@orchid_component/icon";
import {TpiPlanService} from "./service/tpi-menu/tpi-plan.service";
import {TpiReceiptsService} from "./service/tpi-menu/tpi-receipts.service";
import {TpiLogService} from "./service/tpi-menu/tpi-log.service";
import {ImageDefaultDirective} from "./directive/image-default.directive";
import {GridSelectionModalComponent} from "./component/grid-selection-modal/grid-selection-modal.component";
import {NgZorroAntdModule} from "ng-zorro-antd";
import {TpiFacilityService} from "./service/tpi-menu/tpi-facility.service";
import {CommonRequestService} from "./service/common-request/common-request.service";
import {DictionaryService} from "./service/dictionary/dictionary.service";
import {CitySelectorService} from "./service/city-selector/city-selector.service";
import {UserRolesSelectorComponent} from "./component/user-roles-selector/user-roles-selector.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {DictionaryFilter} from "./filter/dictionary.filter";
import {DictionaryVtFilter} from "./filter/dictionary-vt.filter";
import {ProvinceCityCountyFilter} from "./filter/provinceCityCounty.filter";
import {EllipsisDirective} from "./directive/ellipsis.directive";
import {StatusSelectorComponent} from "./component/status-selector/status-selector.component";
import {UserSelectorComponent} from "./component/user-selector/user-selector.component";
import {PersonalListComponent} from "./component/user-selector/personal-list/personal-list.component";
import {OrganizationListComponent} from "./component/user-selector/organization-list/organization-list.component";
import {ChartByCustomComponent} from "./component/chart-by-custom/chart-by-custom.component";
import {ChartByTypeComponent} from "./component/chart-by-type/chart-by-type.component";
import {ChartByWeekComponent} from "./component/chart-by-week/chart-by-week.component";
import {OrchidEchartsModule} from "@orchid_component/echarts";
import {TpiRanchService} from "./service/tpi-menu/tpi-ranch.service";
import {WaveDirective} from "./directive/wave.directive";
import {CustomSelectorComponent} from "./component/custom-selector/custom-selector.component";
import {OrchidFormModule} from "@orchid_component/form";
import {TpiArchivesService} from "./service/tpi-menu/tpi-archives.service";
import {TpiConfigurationService} from "./service/tpi-menu/tpi-configuration.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        EventModule,
        OrchidGridModule,
        OrchidEchartsModule,
        NgZorroAntdModule,
        OrchidIconModule,
        OrchidFormModule
    ],
    declarations: [
        ImageDefaultDirective,
        GridSelectionModalComponent,
        UserRolesSelectorComponent,
        StatusSelectorComponent,
        UserSelectorComponent,
        PersonalListComponent,
        OrganizationListComponent,
        ChartByCustomComponent,
        ChartByTypeComponent,
        ChartByWeekComponent,
        CustomSelectorComponent,

        DictionaryFilter,
        DictionaryVtFilter,
        ProvinceCityCountyFilter,

        EllipsisDirective,
        WaveDirective
    ],
    exports: [
        ImageDefaultDirective,
        GridSelectionModalComponent,
        UserRolesSelectorComponent,
        StatusSelectorComponent,
        UserSelectorComponent,
        ChartByCustomComponent,
        ChartByTypeComponent,
        ChartByWeekComponent,
        CustomSelectorComponent,

        DictionaryFilter,
        DictionaryVtFilter,
        ProvinceCityCountyFilter,

        EllipsisDirective,
        WaveDirective
    ],
    providers: [
        CommonRequestService,
        DictionaryService,
        CitySelectorService,
        { provide: 'tpi.menu', useClass: TpiRanchService, multi: true },
        { provide: 'tpi.menu', useClass: TpiPortalService, multi: true },
        { provide: 'tpi.menu', useClass: TpiPasturelandService, multi: true },
        { provide: 'tpi.menu', useClass: TpiPlanService, multi: true },
        { provide: 'tpi.menu', useClass: TpiReceiptsService, multi: true },
        { provide: 'tpi.menu', useClass: TpiLogService, multi: true },
        { provide: 'tpi.menu', useClass: TpiFacilityService, multi: true },
        { provide: 'tpi.menu', useClass: TpiArchivesService, multi: true },
        { provide: 'tpi.menu', useClass: TpiConfigurationService, multi: true }
    ]
})
export class SharedModule {}
