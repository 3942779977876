/**
 * Created by guanyj on  12/17/19
 */
import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'pms-status-selector',
    templateUrl: './status-selector.component.html',
    styleUrls: ['./status-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StatusSelectorComponent),
            multi: true
        }
    ]
})
export class StatusSelectorComponent implements ControlValueAccessor {

    @Input() statusList = [];

    @Input() largeMode = false;
    status: any;

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        this.status = value;
    }

    handleStatusChange(value: any) {
        this.status = value;
        this.onChange(value);
    }

    private onChange: (value: string | string[]) => void = () => null;
    private onTouched: () => void = () => null;

}
