/**
 * Created by guanyj on  7/15/19
 */
import {Injectable} from "@angular/core";
import {StorageService} from "@orchid_service/storage";

/**
 * 地区类
 */
export class CityModel {
    [key: string]: any;
    /** 城市码 */
    id: number;

    /** 城市名 */
    name: string;

    /** 父级ID */
    pid: number;

    /** 是否为最末端节点 */
    isLeaf?: boolean;
}


@Injectable()
export class CitySelectorService {

    // tslint:disable-next-line:variable-name
    private _cities: Array<CityModel>;
    get cities(): Array<CityModel> {
        if (!this._cities) {
            this._cities = this.$storage.get('cities');
        }
        return this._cities;
    }

    constructor(
        private $storage: StorageService
    ) {}

    /**
     * 获取平铺城市
     */
    public getFlatNodes() {
        return this.cities.map(item => {
            item.isRoot = item.pid === 0;
            item.isLeaf = item.pid !== 0 && !this.cities.find(city => city.pid === item.id);
            return item;
        });
    }

    /**
     * 获取省市区选择器格式
     */
    public getCascaderNodes(): Array<any> {
        return this.getFlatNodes().filter(item => item.id !== 0).map(item => ({
            value: item.id,
            label: item.name,
            parentId: item.pid === 0 ? null : item.pid,
            isLeaf: item.isLeaf
        }));
    }

    /**
     * 获取所有顶级城市
     * @returns {Array<CityModel>}
     */
    public getTopCities(): Array<CityModel> {
        return this.cities.filter(item => item.pid === 0);
    }

    /**
     * 获取某地址下所有的直接子级城市
     * @returns {Array<CityModel>}
     */
    public getChildCities(pid: number): Array<CityModel> {
        return this.cities.filter(item => item.pid === pid).map(item => {
            item.isLeaf = !this.cities.find(city => city.pid === item.id);
            return item;
        });
    }

    public getCityInfo(id: number): CityModel {
        return this.cities.find(item => item.id === id);
    }

    /**
     * 获取省市县
     * @param code
     */
    public getProvinceCityCounty(code: string): string {
        return ((code || '').split(',') || []).map(i => Number(i)).reduce((prev, current) => prev + this.getCityInfo(current).name, '');
    }
}
