/**
 * Created by guanyj on  7/12/19
 */
import {Injectable} from "@angular/core";
import {URL_CONST} from "../../../const/url-const";
import {Version} from "../../../enum/version.enum";
import {HttpService, RequestMsg} from "@orchid_service/http";
import {GridChangeEvent} from "@orchid_component/grid";

@Injectable()
export class OrganizationListService {

    constructor(
        private $http: HttpService
    ) {}

    /**
     * 查询个人用户列表
     * @param {GridChangeEvent} params
     * @returns {Observable<ResponseMsg>}
     */
    queryUserList(params: GridChangeEvent) {
        const request = new RequestMsg();
        request.url = URL_CONST.member.company.list;
        request.body = params;
        request.version = Version.v1;

        return this.$http.post(request);
    }
}
