/**
 * Created by guanyj on  2019-08-27
 */
import {Component, Input, OnInit} from "@angular/core";
import {ChartStyle} from "../../const/chart-style.constant";

@Component({
    selector: 'pms-chart-by-type',
    templateUrl: './chart-by-type.component.html',
    styleUrls: ['./chart-by-type.component.scss']
})
export class ChartByTypeComponent implements OnInit {
    chartOptions;
    chartLoading = true;

    @Input() set list(chartNodes: Array<any>) {
        if (!chartNodes) {
            return;
        }

        const totalCount = chartNodes.reduce((prev, curr) => prev + curr.value, 0);

        this.chartLoading = false;
        this.chartOptions = {
            color: ChartStyle.color,
            legend: {
                // orient: 'vertical',
                x: 'left',
                data: chartNodes.map(item => item.key)
            },
            series: [
                {
                    name: 'aa',
                    type: 'pie',
                    radius: ['0%', '35%'],
                    labelLine: {
                        normal: {
                            show: true
                        }
                    },
                    label: {
                        normal: {
                            formatter: '{hr|}\n {b|{b}：}\n{c} {per|{d}%}  ',
                            backgroundColor: '#fafafa',
                            borderColor: '#ccc',
                            borderWidth: 1,
                            borderRadius: 5,
                            padding: [1, 2],
                            rich: {
                                a: {
                                    color: '#999',
                                    lineHeight: 16,
                                    align: 'center'
                                },
                                hr: {
                                    width: '100%',
                                    borderWidth: 0.5,
                                    height: 0
                                },
                                b: {
                                    fontSize: 12,
                                    lineHeight: 18
                                },
                                per: {
                                    color: '#eee',
                                    backgroundColor: '#334455',
                                    padding: [2, 4],
                                    borderRadius: 1
                                }
                            }
                        }
                    },
                    data: chartNodes.map(item => ({value: item.value, name: item.key}))
                },
            ]
        };
    }

    constructor() {

    }

    ngOnInit(): void {

    }

}

