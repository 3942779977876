/**
 * Created by guanyj on  12/20/19
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiRanchService implements ITpiMenu {

    generateMenuItems(): Array<TpiMenuItem> {
        const menuItems = [];
        const ranchMenuItem = new TpiMenuItem();
        ranchMenuItem.id = 999999;
        ranchMenuItem.name = '牧场管理系统';
        ranchMenuItem.isHide = true;
        ranchMenuItem.isShowBreadcrumb = false;
        ranchMenuItem.type.isRoot = true;
        ranchMenuItem.path = '/ranch';

        menuItems.push(ranchMenuItem);

        return menuItems;
    }
}

