/**
 * Created by guanyj on  7/17/19
 */

/**
 * 字典常量
 */
export const DICTIONARY_CONST = {

    /** 温控模式 */
    temperatureMode: 'temperature_mode',

    /** 建筑构造 */
    construction: 'construction',

    /** 仓储布局 */
    storeType: 'store_type',

    /** 性别 */
    gender: 'CD0006',

    /** 证件类型 */
    cardType: 'CD0014',

    /** 个人证件 */
    personCardType: 'CD0014_C',

    /** 企业证件 */
    customCardType: 'CD0014_B',

    /** 设备品牌 */
    facilityBrand: 'device_brand',

    /** 设备型号 */
    facilityModel: 'device_model',

    /** 牛的品牌 */
    breedOfBull: 'breed',

    /** 牛的花色 */
    colorOfBull: 'color',

    /** 转栏原因 */
    changeStall: 'change_stall',

    /** 出栏原因 */
    deliverReason: 'deliver_reason',

    /** 单据类型 */
    planType: 'plan_type',

    /** 疾病类型 */
    sickness: 'sickness'

};
