/**
 * Created by guanyj on  12/20/19
 */
import {AfterViewInit, Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
    selector: '[pmsWave]'
})
export class WaveDirective implements AfterViewInit {

    animation: any;
    centerX = 0;
    centerY = 0;
    color = '';
    context: any = {};
    element: any = {};
    radius = 0;

    constructor(
        private $el: ElementRef
    ) {
        this.animation = (function() {
            const win = window as any;
            const callback = (callback) => {
                setTimeout(callback.bind(this), 1000 / 60);
            };

            return (
                win.requestAnimationFrame       ||
                win.mozRequestAnimationFrame    ||
                win.oRequestAnimationFrame      ||
                win.msRequestAnimationFrame     ||
                callback
            );
        })();
    }

    ngAfterViewInit(): void {
        const canvas = document.createElement('canvas');
        this.$el.nativeElement.appendChild(canvas);
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        canvas.style.position = 'absolute';
        canvas.style.left = '0';
        canvas.style.top = '0';
        canvas.style.zIndex = '99';
        canvas.style.opacity = '0.3';
        canvas.style.transition = '0.4s';
        canvas.addEventListener('mousedown', this.onPressStart, false);

        canvas.width  = this.$el.nativeElement.offsetWidth;
        canvas.height = this.$el.nativeElement.offsetHeight;
    }

    onPressStart = (event: any) => {
        this.element = event.target;
        this.context = this.element.getContext('2d');
        this.radius = 0;
        this.centerX = event.offsetX;
        this.centerY = event.offsetY;
        this.context.clearRect(0, 0, this.element.width, this.element.height);

        this.draw();
    }

    // 绘制圆形，并且执行动画
    draw = () => {
        // console.log('draw');
        this.context.beginPath();
        this.context.arc(this.centerX, this.centerY, this.radius, 0, 2 * Math.PI, false);
        if (this.radius < this.element.width / 4) {
            this.context.fillStyle = '#2a95f7';
        } else if (this.radius < this.element.width / 2) {
            this.context.fillStyle = '#59abf5';
        } else if (this.radius < this.element.width / 4 * 3) {
            this.context.fillStyle = '#8dc6f9';
        } else {
            this.context.fillStyle = '#d0e8fd';
        }
        this.context.fill();
        this.radius += this.element.width / 30;
        // 通过判断半径小于元素宽度，不断绘制 radius += 2 的圆形
        if (this.radius < this.element.width) {
            this.animation(this.draw);
        } else {
            this.context.clearRect(0, 0, this.element.width, this.element.height);
        }
    }

}
