/**
 * Created by guanyj on  2019-08-08
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiArchivesService implements ITpiMenu {

    generateMenuItems(): Array<TpiMenuItem> {
        const menuItems: TpiMenuItem[] = [];

        const archivesMenuItem = new TpiMenuItem();
        archivesMenuItem.id = 400000;
        archivesMenuItem.name = '档案管理';
        archivesMenuItem.path = '/ranch/archives';
        archivesMenuItem.accessId = '1017';
        menuItems.push(archivesMenuItem);

        const weighMenuItem = new TpiMenuItem();
        weighMenuItem.id = 401000;
        weighMenuItem.accessId = '101710';
        weighMenuItem.name = '称重管理';
        weighMenuItem.icon = 'zhongliang';
        weighMenuItem.path = '/ranch/archives/weigh';
        weighMenuItem.parentId = archivesMenuItem.id;
        menuItems.push(weighMenuItem);

        const manageMenuItem = new TpiMenuItem();
        manageMenuItem.id = 402000;
        manageMenuItem.accessId = '101720';
        manageMenuItem.name = '档案管理';
        manageMenuItem.icon = 'dangan';
        manageMenuItem.path = '/ranch/archives/manage';
        manageMenuItem.parentId = archivesMenuItem.id;
        menuItems.push(manageMenuItem);

        return menuItems;
    }

}
