/**
 * Created by guanyj on  7/23/19
 */
import {Pipe, PipeTransform} from "@angular/core";
import {CitySelectorService} from "../service/city-selector/city-selector.service";


@Pipe({
    name: 'provinceCityCounty'
})
export class ProvinceCityCountyFilter implements PipeTransform {

    constructor(
        private $city: CitySelectorService
    ) {}

    transform(value: string): string {
        if (!value) {
            return '';
        }
        return value.split(',').map(value => Number(value)).map(value => {
            const target = this.$city.getCityInfo(value);
            return target ? target.name : '-';
        }).join('');
    }

}
