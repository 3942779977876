/**
 * Created by guanyj on  2019-08-07
 */
import {Component, OnInit} from "@angular/core";
import {EventService} from "@orchid_service/event";
import {ContextService} from "@orchid_service/context";
import {StorageService} from "@orchid_service/storage";
import {CommonRequestService} from "../../service/common-request/common-request.service";
import {Router} from "@angular/router";
import {AuthService} from "@orchid_service/auth";

@Component({
    selector: 'pms-user-roles-selector',
    templateUrl: './user-roles-selector.component.html',
    styleUrls: ['./user-roles-selector.component.scss']
})
export class UserRolesSelectorComponent implements OnInit {

    /** 用户的身份集合【机构】 */
    roles: Array<any> = [];

    /** 当前机构Id */
    currentRoleId: number;

    constructor(
        private $event: EventService,
        private $router: Router,
        private $ctx: ContextService,
        private $auth: AuthService,
        private $storage: StorageService,
        private $commonRequest: CommonRequestService
    ) { }

    ngOnInit(): void {
        if (this.$ctx.getUserStatus()) {
            const userInfo = this.$ctx.getUserInfo();
            if (userInfo) {
                this.roles = userInfo.config.roles;
                this.currentRoleId = userInfo.user.organizationId || userInfo.user.memberId;

                const roles = this.roles.map(item => {
                    return {
                        个人用户Id: item.memberId,
                        个人用户名称: item.memberName,
                        机构Id: item.organizationId,
                        机构名: item.organizationName
                    };
                });
                console.table(roles);
            }
        }
    }

    /**
     * 切换用户身份
     * @param id
     */
    onUserRoleChange(id: number) {
        const role = this.roles.find(item => (item.organizationId || item.memberId) === id);
        if (role) {
            // 更新缓存中的当前用户信息
            const userInfo = this.$ctx.getUserInfo();
            userInfo.user = role;
            this.$ctx.showLoading();
            this.$commonRequest.queryPermissionList(role).subscribe(result => {
                // 缓存用户拥有的菜单权限
                const permissionList = [];
                (function getPermissionCode(list) {
                    list.forEach(item => {
                        permissionList.push(item.code);
                        if (item.children && item.children.length > 0) {
                            getPermissionCode(item.children);
                        }
                    });
                })(result.data);
                this.$storage.set('user.info', userInfo);
                const needReload = this.$router.url === '/ranch/portal';

                // 回到首页，发布当前用户和权限发生变化信息
                this.$router.navigateByUrl('/ranch/portal').then(() => {
                    this.$ctx.hideLoading();
                    this.$ctx.setPermissionSetting(permissionList,
                        userInfo.user.organizationId || userInfo.user.memberId, []);

                    // tslint:disable-next-line:no-unused-expression
                    needReload && window.location.reload();
                });
            });
        }
    }
}
