/**
 * Created by guanyj on  12/20/19
 */
import {NgModule} from "@angular/core";
import {SystemRanchRoutingModule} from "./system-ranch-routing.module";
import {SystemRanchComponent} from "./system-ranch.component";
import {NgZorroAntdModule} from "ng-zorro-antd";
import {OrchidIconModule} from "@orchid_component/icon";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from '@angular/common/http';
import {OrchidLayoutModule} from '@orchid_component/layout';
import {OrchidSsoPanelModule} from '@orchid_component/sso-panel';
import {EventModule} from '@orchid_service/event';
import {ContextModule} from '@orchid_service/context';
import {SharedModule} from "./shared/shared.module";
import {I18nModule} from "@orchid_service/i18n";
import {JumpComponent} from "./jump/jump.component";

@NgModule({
    declarations: [
        SystemRanchComponent,
        JumpComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgZorroAntdModule,
        OrchidIconModule,
        HttpClientModule,
        OrchidLayoutModule,
        OrchidSsoPanelModule,
        EventModule,
        ContextModule,
        I18nModule,
        SystemRanchRoutingModule,
        SharedModule
    ]
})
export class SystemRanchModule {}
