/**
 * Created by guanyj on  12/31/19
 */
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {OrchidFormGroup} from "@orchid_component/form";
import _ from 'lodash';
import {UtilsService} from "@orchid_service/utils";

@Component({
    selector: 'pms-custom-selector',
    templateUrl: './custom-selector.component.html',
    styleUrls: ['./custom-selector.component.scss']
})
export class CustomSelectorComponent {
    formRules: OrchidFormGroup = new OrchidFormGroup();
    tags: Array<any> = [];

    active = true;

    /**
     * 查询条件配置，与表单配置类似
     * @desc 不要配置验证规则等
     * @param rules
     */
    @Input() set customRules(rules: OrchidFormGroup) {
        // this.formRules = _.cloneDeep(rules);
        this.formRules = rules;
        this.formRules.showCancelBtn = false;
        this.formRules.showSubmitBtn = false;
        this.formRules.labelWidth = 4;
        this.formRules.controlWidth = 10;

        this.formRules.valueChanges.subscribe(value => {
            Object.keys(value).forEach(key => {
                if (value[key]) {
                    const tag = this.tags.find(item => item.field === key);
                    const control = this.formRules.getControl(key);

                    if (tag) {
                        tag.label = `${control.label}: ${this.ellipseLabel(control.transform(value[key]))}`;
                    } else {
                        this.tags.push({
                            field: key,
                            disabled: control.ignoreWhenReset,
                            label: `${control.label}: ${this.ellipseLabel(control.transform(value[key]))}`
                        });
                    }
                } else {
                    const index = this.tags.findIndex(item => item.field === key);
                    if (index !== -1) {
                        this.tags.splice(index, 1);
                    }
                }
            });
        });
    }

    /**
     * 点击查询回调
     */
    @Output() query = new EventEmitter<any>();

    constructor(
        private $util: UtilsService
    ) {}

    handleTagClose(field: string) {
        this.formRules.getControl(field).reset();
    }

    toggleCollapse() {
        this.active = !this.active;
    }

    handleQuery() {
        this.query.emit(_.cloneDeep(this.formRules.value));
    }

    handleReset() {
        this.formRules.reset();
    }

    private ellipseLabel(label: string) {
        if (label.length > 14) {
            return label.slice(0, 14) + '...';
        }
        return label;
    }
}


