/**
 * Created by guanyj on  7/12/19
 */
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PersonalListService} from "./personal-list.service";
import {DictionaryService} from "../../../service/dictionary/dictionary.service";
import {GridChangeEvent, GridColumns, GridModel, IBaseGrid} from "@orchid_component/grid";
import {DICTIONARY_CONST} from "../../../const/dictionary.const";

@Component({
    selector: 'pms-personal-list',
    templateUrl: './personal-list.component.html',
    styleUrls: ['./personal-list.component.scss'],
    providers: [PersonalListService]
})
export class PersonalListComponent implements IBaseGrid, OnInit {

    @Output() selectionChange = new EventEmitter<any>();

    viewData: GridModel;
    columns: GridColumns[];
    queryParams: GridChangeEvent = new GridChangeEvent();

    constructor(
        private $service: PersonalListService,
        private $dictionary: DictionaryService
    ) {}

    ngOnInit(): void {
        this.initColumnsSetting();
        this.loadGridDataSource();
    }

    initColumnsSetting(): void {
        this.columns = [
            {field: 'name', title: '姓名', width: 120, filterType: 'string'},
            {field: 'gender', title: '性别', width: 100, filterType: 'dropdown',
                dropdown: this.$dictionary.getDictionaryByCode(DICTIONARY_CONST.gender)},
            {field: 'mobile', title: '手机号', width: 200},
            {field: 'idType', title: '证件类型', width: 180, filterType: 'dropdown',
                dropdown: this.$dictionary.getDictionaryByCode(DICTIONARY_CONST.personCardType)},
            {field: 'idNo', title: '证件号码'}
        ];
    }

    onSelectionChange(event: any) {
        this.selectionChange.emit({data: event, isOrg: false});
    }

    onDataSourceChange(event: GridChangeEvent): void {
        this.queryParams = event;
        this.loadGridDataSource();
    }

    loadGridDataSource(): void {
        this.$service.queryUserList(this.queryParams).subscribe(result => {
            this.viewData = result.data;
        });
    }

}
