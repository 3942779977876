import {Component} from '@angular/core';
import {TpiService} from "@orchid_service/tpi";

@Component({
    selector: 'pms-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        private $tpi: TpiService
    ) {
        this.$tpi.initMenus();
    }
}
