/**
 * Created by guanyj on  7/15/19
 */

/**
 * 接口版本
 */
export enum Version {

    v1 = 'v1',

    v2 = 'v2',

    v3 = 'v3',

    v4 = 'v4'
}
