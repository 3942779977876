/**
 * Created by guanyj on  7/17/19
 */
import {Pipe, PipeTransform} from "@angular/core";
import _ from 'lodash';
import {StorageService} from "@orchid_service/storage";

@Pipe({
    name: 'dictionary'
})
export class DictionaryFilter implements PipeTransform {

    /** 所有词条 */
    private dictionaries = {};

    constructor(
        private $storage: StorageService
    ) {}

    /**
     *
     * @param value 字典词条名
     * @returns {Array<{value: any, label: string}>}
     */
    transform(value: any): Array<{value: any, label: string}> {
        if (_.isEmpty(this.dictionaries)) {
            this.dictionaries = this.$storage.get('dictionary');
        }
        return this.dictionaries[value].map(item => ({value: item.id, label: item.nodeName}));
    }

}
