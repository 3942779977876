
export const I18n = {

    "orchid.pms.ranch": "牧场",
    "orchid.pms.ranch.host": "所有人",
    "orchdi.pms.plan.pch": "入栏批次号",
    "orchdi.pms.plan.date": "计划日期",
    "orchdi.pms.plan.ranch.name": "入栏牧场",
    "orchdi.pms.plan.operator": "操作人",
    "orchid.pms.plan.lairage": "入栏计划",
    "orchid.pms.plan.outrage": "出栏计划",
    "orchid.pms.plan.lairage.log": "入栏记录",
    "orchid.pms.plan.outrage.log": "出栏记录",
    "orchid.pms.plan": "计划管理",
    "orchid.pms.status": "状态",
    "orchid.facility.module.name": "设备资产",
    "orchid.facility.monitor": "监控设备",
    "orchid.facility.lock": "门锁设备",
    "orchid.facility.rf": "RF设备",
    "orchid.facility.name": "设备名称",
    "orchid.facility.no": "设备编号",
    "orchid.common.brand": "品牌",
    "orchid.common.version": "型号",
    "orchid.facility.useing": "是否启用",
    "orchid.facility.connect.status": "连接状态",
    "orchid.facility.flow.warn": "流量提醒",
    "orchid.pms.sure": "确认",
    "orchid.pms.facility.lock.name": "电子锁名称",
    "orchid.pms.facility.lock.serial": "设备序列号",
    "orchid.facility.open.status": "开关状态",
    "orchid.common.remark": "备注",
    "orchid.pms.facility.lock.power": "电量",
    "orchid.pms.facility.lock.pos": "位置",
    "orchid.pms.configuration": "配置管理",
    "orchid.pms.breed": "品种管理",
    "orchid.pms.conf.breed.firstCategoryName": "所属行业",
    "orchid.pms.conf.breed.secondCategoryName": "所属品种",
    "orchid.pms.conf.breed.thirdCategoryName": "所属品类",
    "orchid.pms.conf.breed.spu": "SPU",
    "orchid.pms.conf.breed.monthAge": "月龄（月）",
    "orchid.pms.conf.breed.weight": "标准体重（KG）",
    "orchid.pms.conf.breed.averageAddWeight": "月日均增重（KG）",
    "orchid.pms.conf.breed.sku": "SKU",
    "orchid.pms.conf.breed.price": "价格关联",
    "orchid.pms.conf.disease": "疾病类型",
    "orchid.pms.conf.disease.name": "疾病名称",
    "orchid.pms.conf.disease.type": "疾病类型",
    "orchid.pms.conf.disease.symptom": "症状描述",
    "orchid.pms.conf.disease.remark": "备注",
    "orchid.pms.conf.vaccine": "疫苗类型",
    "orchid.pms.conf.vaccine.name": "疫苗名称",
    "orchid.pms.conf.vaccine.description": "疫苗程序",
    "orchid.pms.conf.fodder": "饲料配方",
    "orchid.pms.conf.fodder.name": "饲料名称",
    "orchid.pms.conf.fodder.stageName": "适用阶段",
    "orchid.pms.conf.fodder.recipe": "配方原料",
    "orchid.pms.archives": "档案管理",
    "orchid.pms.archives.weigh": "称重管理",
    "orchid.pms.common.no": "编号",
    "orchid.pms.archives.code": "主耳标号",
    "orchid.pms.archives.ranch": "所属牧场",
    "orchid.pms.archives.stall": "栏舍名称",
    "orchid.pms.archives.weight.in": "入栏体重（KG）",
    "orchid.pms.archives.weight.now": "实时体重（KG）",
    "orchid.pms.archives.latest": "最新称重时间",
    "orchid.pms.common.w.estimate": "估算体重（KG）",
    "orchid.pms.common.w.unit": "个体体重（KG）",
    "orchid.pms.common.marketvalue": "市场价值（元）",
    "orchid.common.status.health": "健康状态",
};
