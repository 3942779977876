/**
 * Created by guanyj on  2019-08-27
 */
import {Component, Input, OnInit} from "@angular/core";
import {ChartStyle} from "../../const/chart-style.constant";

@Component({
    selector: 'pms-chart-by-week',
    templateUrl: './chart-by-week.component.html',
    styleUrls: ['./chart-by-week.component.scss']
})
export class ChartByWeekComponent implements OnInit {
    chartOptions;

    chartLoading = true;

    @Input() set list(chartNodes: Array<any>) {
        if (!chartNodes) {
            return;
        }
        this.chartLoading = false;

        this.chartOptions = {
            legend: {
                data: ['入栏', '出栏', '点数'],
                align: 'left',
                left: 10
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                padding: 10,
                formatter: (params) => {

                    const time = params[0].name;
                    let label = `<h5 style="font-size: 16px;color: white;">${time}</h5>`;
                    params.forEach(item => {
                        label += `<p style="margin: 0; padding: 0;color: ${item.color}">${item.seriesName}: ${item.value}次</p>`;
                    });
                    return label;
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: chartNodes.map(item => item.date),
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            color: ChartStyle.color,
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    name: '入栏',
                    type: 'bar',
                    stack: 'three',
                    barWidth: '60%',
                    barMaxWidth: '64px',
                    data: chartNodes.map(item => item.inStallCount)
                },
                {
                    name: '出栏',
                    type: 'bar',
                    stack: 'three',
                    barWidth: '60%',
                    barMaxWidth: '64px',
                    data: chartNodes.map(item => item.outStallCount)
                },
                {
                    name: '点数',
                    type: 'bar',
                    stack: 'three',
                    barWidth: '60%',
                    barMaxWidth: '64px',
                    data: chartNodes.map(item => item.onHandCount)
                }
            ]
        };
    }

    constructor() {
    }

    ngOnInit(): void {

    }
}
