/**
 * Created by guanyj on  7/12/19
 * todo 待原型确定优化
 */
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {PersonalListComponent} from "./personal-list/personal-list.component";
import {OrganizationListComponent} from "./organization-list/organization-list.component";

export class UserSelectorEvent {

    organization: any;

    member: any;

    mode: string;
}

@Component({
    selector: 'pms-user-selector',
    templateUrl: './user-selector.component.html',
    styleUrls: ['./user-selector.component.scss'],
    entryComponents: [
        PersonalListComponent,
        OrganizationListComponent
    ]
})
export class UserSelectorComponent {

    @Input() visible = false;

    @Input() showMemberTab = true;

    @Output() cancel = new EventEmitter();
    @Output() ok = new EventEmitter<UserSelectorEvent>();

    selectionList: UserSelectorEvent = new UserSelectorEvent();

    private isOrg = false;

    onSelectionChange(event: { data: any, isOrg: boolean }) {
        if (event.isOrg) {
            this.selectionList.organization = event.data;
            this.selectionList.member = null;
        } else {
            this.selectionList.member = event.data;
            this.selectionList.organization = null;
        }

        this.ok.emit(this.selectionList);
    }

}
