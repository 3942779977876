/**
 * Created by guanyj on  2019-08-08
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiConfigurationService implements ITpiMenu {

    generateMenuItems(): Array<TpiMenuItem> {
        const menuItems: TpiMenuItem[] = [];

        const configurationMenuItem = new TpiMenuItem();
        configurationMenuItem.id = 700000;
        configurationMenuItem.accessId = '1016';
        configurationMenuItem.name = '配置管理';
        configurationMenuItem.path = '/ranch/configuration';
        menuItems.push(configurationMenuItem);

        const breedMenuItem = new TpiMenuItem();
        breedMenuItem.id = 701000;
        breedMenuItem.accessId = '101610';
        breedMenuItem.name = '品种管理';
        breedMenuItem.icon = 'gongcheng';
        breedMenuItem.path = '/ranch/configuration/breed';
        breedMenuItem.parentId = configurationMenuItem.id;
        menuItems.push(breedMenuItem);

        const diseaseMenuItem = new TpiMenuItem();
        diseaseMenuItem.id = 702000;
        diseaseMenuItem.accessId = '101620';
        diseaseMenuItem.name = '疾病类型';
        diseaseMenuItem.icon = 'jibing';
        diseaseMenuItem.path = '/ranch/configuration/disease';
        diseaseMenuItem.parentId = configurationMenuItem.id;
        menuItems.push(diseaseMenuItem);

        const vaccineMenuItem = new TpiMenuItem();
        vaccineMenuItem.id = 703000;
        vaccineMenuItem.accessId = '101630';
        vaccineMenuItem.name = '疫苗类型';
        vaccineMenuItem.icon = 'fenzu';
        vaccineMenuItem.path = '/ranch/configuration/vaccine';
        vaccineMenuItem.parentId = configurationMenuItem.id;
        menuItems.push(vaccineMenuItem);

        const fodderMenuItem = new TpiMenuItem();
        fodderMenuItem.id = 704000;
        fodderMenuItem.accessId = '101640';
        fodderMenuItem.name = '饲料配方';
        fodderMenuItem.icon = 'siliao';
        fodderMenuItem.path = '/ranch/configuration/fodder';
        fodderMenuItem.parentId = configurationMenuItem.id;
        menuItems.push(fodderMenuItem);

        return menuItems;
    }

}
