/**
 * Created by guanyj on  7/12/19
 */
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {OrganizationListService} from "./organization-list.service";
import {DictionaryService} from "../../../service/dictionary/dictionary.service";
import {GridChangeEvent, GridColumns, GridModel, IBaseGrid} from "@orchid_component/grid";
import {DICTIONARY_CONST} from "../../../const/dictionary.const";

@Component({
    selector: 'pms-organization-list',
    templateUrl: './organization-list.component.html',
    styleUrls: ['./organization-list.component.scss'],
    providers: [OrganizationListService]
})
export class OrganizationListComponent implements IBaseGrid, OnInit {

    @Output() selectionChange = new EventEmitter<any>();

    viewData: GridModel;
    columns: GridColumns[];
    queryParams: GridChangeEvent = new GridChangeEvent();

    constructor(
        private $dictionary: DictionaryService,
        private $service: OrganizationListService
    ) {}

    ngOnInit(): void {
        this.initColumnsSetting();
        this.loadGridDataSource();
    }

    initColumnsSetting(): void {
        this.columns = [
            {field: 'compName', title: '机构全称', width: 200, filterType: 'string'},
            {field: 'corporation', title: '法定代表人姓名', width: 100, filterType: 'string'},
            {field: 'idType', title: '证件类型', width: 160, filterType: 'dropdown',
                dropdown: this.$dictionary.getDictionaryByCode(DICTIONARY_CONST.customCardType)},
            {field: 'idNo', title: '证件号码', filterType: 'string'}
        ];
    }

    onSelectionChange(event: any) {
        this.selectionChange.emit({data: event, isOrg: true});
    }

    onDataSourceChange(event: GridChangeEvent): void {
        this.queryParams = event;
        this.loadGridDataSource();
    }

    loadGridDataSource(): void {
        this.$service.queryUserList(this.queryParams).subscribe(result => {
            this.viewData = result.data;
        });
    }

}
