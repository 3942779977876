/**
 * Created by guanyj on  7/9/19
 */

export const URL_CONST = {
    common: {
        // 数据字典【省市区】
        distinct: '/orchid-unify/dict/list/distric/all',
        // 权限列表
        permission: '/orchid-unify/auth/resource',
        // 查询密钥
        defaultKaptcha: '/orchid-unify/auth/login/defaultKaptcha',
        // 查询字典
        dictionary: '/orchid-unify/dict/list/types',
        // 绑定附件
        bindAttach: '/orchid-unify/attach/update',
        // 删除附件
        deleteAttach: '/orchid-unify/attach/delete',
        // 由业务流水号查附件信息
        findSubType: '/orchid-unify/attach/findSubType'
    },

    // 人员管理
    member: {
        company: {
            list: '/orchid-pms-operator/member/company/list'
        },
        person: {
            list: '/orchid-pms-operator/member/person/list'
        },
        // 查询企业员工
        compList: '/orchid-pms-operator/member/companyStaff/list'
    },

    // 计划管理
    plan: {
        // 入栏计划
        lairage: {
            // 列表
            list: '/orchid-pms-operator/plan/list',
            // 入栏牧场列表
            ranchList: '/orchid-pms-operator/ranch/list',
            // 新增
            add: '/orchid-wms-operator/plan/lairage/add',
            // 编辑
            update: '/orchid-wms-operator/plan/lairage/update',
            // 详情
            detail: {
                prev: '/orchid-pms-operator/plan/detail',
                next: '/orchid-pms-operator/plan/detail/list'
            },
            // 删除
            delete: '/orchid-wms-operator/plan/lairage/delete',
        },
        // 入栏记录
        installRecord: '/orchid-pms-operator/plan/record/list',
        // 出栏计划
        slaughter: {
            // 列表
            list: '/orchid-pms-operator/plan/detail',
            // 创建出栏计划
            create: {
                outTemplate: '/orchid-pms-operator/plan/out/goods/list'
            }

        },
        // 盘点管理
        inventory: {
            // 点数列表
            list: '/orchid-pms-operator/plan/tally/list',
            // 点数详情列表接口
            detail: '/orchid-pms-operator/plan/tally/detail/list',
            // 状态
            statusList: '/orchid-pms-operator/plan/tally/detail/count',
            // sku
            skuList: '/orchid-pms-operator/plan/in/goods/list'
        },
        uploadFile: '/orchid-pms-operator/plan/import',
        createPlan: {
            commitInstallRanch: '/orchid-pms-operator/plan/commit'
        }
    },
    // 牧场管理相关接口
    pastureland: {
        // 牧场列表
        list: '/orchid-pms-operator/ranch/list',
        // 新增牧场
        add: '/orchid-pms-operator/ranch/add',
        // 更新牧场
        update: '/orchid-pms-operator/ranch/update',
        // 牧场详情
        detail: '/orchid-pms-operator/ranch/info',
        // 栏舍集合
        owners: '/orchid-pms-operator/ranch/list/customer',
        // 品种树
        category: '/orchid-pms-operator/category/business/tree',
        // 品种下拉列表
        skuList: '/orchid-pms-operator/plan/in/goods/list',

        statistic: {
            ranch: '/orchid-pms-operator/pda/forward?uri=/statistic/ranch',
            today: '/orchid-pms-operator/pda/forward?uri=/statistic/ranch/today',
            custom: '/orchid-pms-operator/pda/forward?uri=/statistic/ranch/rank',
            week: '/orchid-pms-operator/pda/forward?uri=/statistic/ranch/week',
            sku: '/orchid-pms-operator/pda/forward?uri=/statistic/ranch/spu'
        },

        livestock: {
            // 在栏清单
            list: '/orchid-pms-operator/livestock/list',
            // 创建在栏单
            add: '/orchid-pms-operator/livestock/plan/commit',
            // 编辑活体
            update: '/orchid-pms-operator/livestock/edit',
            // 活体图片
            pictures: '/orchid-pms-operator/livestock/livestock/pic/list',
            // 查询品种单价
            priceOfSku: '/orchid-pms-operator/livestock/price/bySkuId',
            // 在栏更新时间
            updateTime: '/orchid-pms-operator/livestock/latest/updateTime',
            //最大耳标号
            maxCode:'/orchid-pms-operator/ranch/forward?uri=/livestock/maxCode/byRanch/v1',
            // 批量操作
            batch: {
                // 批量编辑活体
                edit: '/orchid-pms-operator/livestock/batch/edit',
                // 批量转栏
                exchange: '/orchid-pms-operator/livestock/batch/exchange',
                // 批量出栏
                out: '/orchid-pms-operator/livestock/batch/out',
                // 批量耳标变更
                updateCode: '/orchid-pms-operator/livestock/batch/updateCode'
            }
        },
        // 牧场设备
        facility: {
            // 耳标清单
            eartag: '/orchid-pms-operator/device/ranch/code/list',
            // 批量更新耳标状态
            updateEarTag: '/orchid-pms-operator/device/ranch/code/update',
            // 牧场下监控设备
            monitorList: '/orchid-pms-operator/device/ranch/camera/list',
            // 全量监控设备集合
            monitorFullList: '/orchid-pms-operator/device/org/camera/list',

            unbindMonitorList: '/orchid-pms-operator/device/unbind/list',
            // 绑定牧场下摄像头
            monitorBind: '/orchid-pms-operator/device/bind/unbind'
        },
        setting: {
            // 员工列表
            memberList: '/orchid-pms-operator/ranch/list/employee',
            // 牧场绑定库管
            bindMember: '/orchid-pms-operator/ranch/bind/employee',
            // 解绑牧场库管
            unbindMember: '/orchid-pms-operator/ranch/unbind/employee',
            // 客户列表
            customerList: '/orchid-pms-operator/ranch/list/customer',
            // 牧场绑定客户
            bindCustomer: '/orchid-pms-operator/ranch/bind/customer',
            // 解绑牧场客户
            unbindCustomer: '/orchid-pms-operator/ranch/unbind/customer',
            // 更换牧场管理方
            changeManager: '/orchid-pms-operator/ranch/bind/manager',
            // 保存商品分类
            saveCategory: '/orchid-pms-operator/category/batch/save',
            // 牧场下栏舍相关
            stall: {
                list: '/orchid-pms-operator/ranch/stall/list',
                add: '/orchid-pms-operator/ranch/stall/add',
                update: '/orchid-pms-operator/ranch/stall/update',
                delete: '/orchid-pms-operator/ranch/stall/delete'
            }
        }
    },
    // 设备资产
    facility: {
        // 资产设备
        list: '/orchid-wms-operator/device/findDeviceByDeviceType',
        // 查询资产全集
        fullList: '/orchid-wms-operator/device/findAllDevice',
        // 绑定与解绑
        bindAndUnbind: '/orchid-wms-operator/device/bindAndRemove',
        monitor: {
            // 查询全量的监控设备列表
            listOfWarehouse: '/orchid-wms-operator/device/all/camera',
            list: '/orchid-wms-operator/device/camera/warehouseId',
            add: '/orchid-wms-operator/device/camera/add',
            update: '/orchid-wms-operator/device/camera/update',
            delete: '/orchid-wms-operator/device/camera/delete',
            videoUrl: '/orchid-wms-operator/device/videoUrlConfig'
        },
        // 电子锁
        lock: {
            // 查全量的电子锁集合
            listOfWarehouse: '/orchid-wms-operator/device/all/lock',
            list: '/orchid-wms-operator/device/lock/warehouseId',
            add: '/orchid-wms-operator/device/lock/add',
            update: '/orchid-wms-operator/device/lock/update',
            delete: '/orchid-wms-operator/device/lock/delete',
            freshStatus: '/orchid-wms-operator/device/fresh/lock/status'
        },
        options: {
            // 关闭摄像头
            closeEncrypt: '/orchid-wms-operator/device/closeEncrypt',
            openEncrypt: '/orchid-wms-operator/device/openEncrypt',
            // 更新行动预测时间/周期
            defence: '/orchid-wms-operator/device/defenceTime',
            // 是否开启录像
            record: '/orchid-wms-operator/device/switchRecord',
            // 是否开启移动侦测
            sense: '/orchid-wms-operator/device/switchSense',
            // 是否开启离线提醒
            offlineNotify: '/orchid-wms-operator/device/switchOffLineNotify',
            // 获取设备播放地址
            videoUrl: '/orchid-wms-operator/device/videoUrlConfig'
        }
    },
    // 配置管理
    configuration: {
        // 疾病相关
        disease: {
            // 列表
            list: '/orchid-pms-operator/disease/list',
            // 创建
            create: '/orchid-pms-operator/disease/create',
            // 删除
            delete: '/orchid-pms-operator/disease/delete'
        },
        // 疫苗相关
        vaccine: {
            // 列表
            list: '/orchid-pms-operator/vaccine/list',
            // 创建
            create: '/orchid-pms-operator/vaccine/create',
            // 删除
            delete: '/orchid-pms-operator/vaccine/delete'
        },
        // 饲料相关
        forage: {
            // 列表
            list: '/orchid-pms-operator/forage/list',
            // 创建
            create: '/orchid-pms-operator/forage/create',
            // 删除
            delete: '/orchid-pms-operator/forage/delete'
        },
        // 品种相关
        categoryfatten: {
            // 列表
            list: '/orchid-pms-operator/categoryfatten/list',
            // 创建
            create: '/orchid-pms-operator/categoryfatten/create',
            // 删除
            delete: '/orchid-pms-operator/categoryfatten/delete'
        },
        // 级联相关接口
        cascade: {
            // 行业列表
            businessList: '/orchid-pms-operator/category/business/list',
            // 品种列表
            // 品类列表
            // SPU
            list: '/orchid-pms-operator/category/list',
            // SKU
            sku: '/orchid-pms-operator/categoryfatten/sku/list',
            // 月龄
            month: '/orchid-pms-operator/categoryfatten/last/get',
        },

    },
    // 档案管理
    archives: {
        //  根据牧场选择栏舍
        stallList: '/orchid-pms-operator/ranch/forward?uri=/stall/list/byRanch',
        // 根据牧场选择SPU
        category: '/orchid-pms-operator/category/ranch/spu/list',
        // 称重管理
        weigh: {
            list: '/orchid-pms-operator/livestock/weight/list',
            add: '/orchid-pms-operator/livestock/weight/create',
            download: '/orchid-pms-operator/livestock/weight/template/download',
            import: '/orchid-pms-operator/livestock/weight/template/import'
        },
        // 档案管理
        manage: {
            list: '/orchid-pms-operator/livestock/list',
            detail: '/orchid-pms-operator/livestock/get',
            feeding: '/orchid-pms-operator/livestock/feed',
            healthManage: '/orchid-pms-operator/livestock/health/manage',
            healthRegister: '/orchid-pms-operator/livestock/health/record',
            // 疫苗登记
            vaccineRegister: '/orchid-pms-operator/livestock/vaccine/record',
            // 饲料配方
            forage: '/orchid-pms-operator/forage/list',
            // 疫苗类型
            vaccine: '/orchid-pms-operator/vaccine/list',
            // 个体牛只价值趋势图
            chartOfValue: '/orchid-pms-operator/livestock/statistic/value',
            // 个体牛只体重趋势图
            chartOfWeight: '/orchid-pms-operator/livestock/statistic/weight',
            // 疾病列表
            diseaseList: '/orchid-pms-operator/disease/list',
            // 查询多个牛只的治疗信息
            healthTreatInfo: '/orchid-pms-operator/livestock/health/manage/get',
            // 饲养配方
            chartOfFeed: '/orchid-pms-operator/livestock/statistic/recipe'
        }
    }
};

