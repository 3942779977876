/**
 * Created by guanyj on  12/20/19
 */
import {Component, OnInit} from "@angular/core";
import {TpiService} from "@orchid_service/tpi";
import {StorageService} from "@orchid_service/storage";
import {EventService} from "@orchid_service/event";
import {en_US, NzI18nService} from "ng-zorro-antd";
import {RequestMsg, HttpService} from "@orchid_service/http";

@Component({
    templateUrl: './system-ranch.component.html',
    styleUrls: ['./system-ranch.component.scss']
})
export class SystemRanchComponent implements OnInit {

    menuMode: string;

    projects: Array<any> = [];

    prod = false;

    constructor(
        private $tpi: TpiService,
        private $http: HttpService,
        private $storage: StorageService,
        private $event: EventService,
        private $zorroI18n: NzI18nService
    ) {}

    ngOnInit(): void {
        this.menuMode = this.$storage.get('menu.style') || 'default';

        this.$event.subscribe('wms.event.menu.style', value => {
            this.menuMode = value;
        });

        this.queryDomains();
    }

    handleLogout() {
        this.$tpi.logout();
    }

    handleChangeMenuMode() {
        this.$storage.set('menu.style', this.menuMode === 'default' ? 'simple' : 'default');
        this.$event.publish('wms.event.menu.style', this.menuMode === 'default' ? 'simple' : 'default');
    }

    eggshell() {
        this.$zorroI18n.setLocale(en_US);
    }

    private queryDomains() {
        const request = new RequestMsg();
        request.url = '/orchid-unify/dict/list/pc/v1';
        request.body = {};

        this.$http.post(request).subscribe(result => {
            this.projects = result.data.filter(item =>
                item.appName !== 'pms_pc'
            ).map(item => {
                return {
                    name: item.plateName,
                    domain: item.visitorUrl,
                    appName: item.appName
                };
            });
        });
    }
}
