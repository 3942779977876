import { HttpService } from '@orchid_service/http';
/**
 * Created by guanyj on  5/11/20
 */
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ContextService} from "@orchid_service/context";
import { TpiLoginOAuth, TpiService } from '@orchid_service/tpi';
import { environment } from 'src/environments/environment';
import { EventService } from '@orchid_service/event';
import { StorageService } from '@orchid_service/storage';
import { CommonRequestService } from '../shared/service/common-request/common-request.service';
import { CitySelectorService } from '../shared/service/city-selector/city-selector.service';
import { ResponseMsg, RequestMsg } from '@orchid_service/http';
import { Observable } from 'rxjs';

@Component({
    template: `<p>请稍候...</p>`,
})
export class JumpComponent implements OnInit {

    constructor(
        private $ctx: ContextService,
        private $router: Router,
        private $tpi: TpiService,
        private $active: ActivatedRoute,
        private $event: EventService,
        private $cities: CitySelectorService,
        private $storage: StorageService,
        private $http: HttpService,
        private $commonRequest: CommonRequestService
    ) {}

    ngOnInit(): void {
        const {memberId, organizationId, openId, target_path, query} = this.$active.snapshot.queryParams;
        const input = new TpiLoginOAuth();
        input.token = openId;
        input.appName = environment.appName;
        input.memberId = memberId;
        input.organizationId = organizationId;

        this.$tpi.loginOAuth(input).subscribe(result => {
            this.$router.navigate(['/ranch/portal']).then(() => {
                // 缓存前端省市县数据
                this.queryDistrictList().subscribe(result => {
                    this.$storage.set('cities', result.data);
                });
                this.cacheDictionary();
            })
        });
    }


    cacheDictionary() {
        this.$commonRequest.getDictionary().subscribe(result => {
            this.$storage.set('dictionary', result.data);
        });
    }

/**
     * 查询地区数据列表
     * @returns {Observable<ResponseMsg<Array<CityModel>>>}
     */
    public queryDistrictList(): Observable<ResponseMsg<Array<any>>> {
        const request = new RequestMsg();
        request.url = '/orchid-unify/dict/list/distric/all';
        request.version = 'v1';

        return this.$http.post(request);
    }
}
