/**
 * Created by guanyj on  12/9/19
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiLogService implements ITpiMenu {

    generateMenuItems(): Array<TpiMenuItem> {
        const menuItems = [];

        const logMenuItem = new TpiMenuItem();
        logMenuItem.id = 800000;
        logMenuItem.name = '消息日志';
        logMenuItem.path = '/ranch/log';
        logMenuItem.accessId = '1015';
        menuItems.push(logMenuItem);
        return menuItems;
    }

}
