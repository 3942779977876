/**
 * Created by guanyj on  12/9/19
 */
import {Injectable} from "@angular/core";
import {ITpiMenu, TpiMenuItem} from "@orchid_service/tpi";

@Injectable()
export class TpiPlanService implements ITpiMenu {
    generateMenuItems(): Array<TpiMenuItem> {
        const menuItems = [];

        const planMenuItem = new TpiMenuItem();
        planMenuItem.id = 300000;
        planMenuItem.name = '计划管理';
        planMenuItem.path = '/ranch/plan';
        planMenuItem.accessId = '1011';
        menuItems.push(planMenuItem);

        const laigeMenuItem = new TpiMenuItem();
        laigeMenuItem.id = 300001;
        laigeMenuItem.name = '入栏计划';
        laigeMenuItem.path = '/ranch/plan/lairage';
        laigeMenuItem.icon = 'rulan';
        laigeMenuItem.accessId = '101110';
        laigeMenuItem.parentId = planMenuItem.id;
        menuItems.push(laigeMenuItem);

        const lairageRecordMenuItem = new TpiMenuItem();
        lairageRecordMenuItem.id = 300002;
        lairageRecordMenuItem.name = '入栏记录';
        lairageRecordMenuItem.path = '/ranch/plan/lairageRecord';
        lairageRecordMenuItem.icon = 'rulan';
        lairageRecordMenuItem.accessId = '101113';
        lairageRecordMenuItem.parentId = planMenuItem.id;
        menuItems.push(lairageRecordMenuItem);


        const slaughterMenuItem = new TpiMenuItem();
        slaughterMenuItem.id = 300003;
        slaughterMenuItem.name = '出栏计划';
        slaughterMenuItem.path = '/ranch/plan/slaughter';
        slaughterMenuItem.icon = 'chulan';
        slaughterMenuItem.accessId = '101111';
        slaughterMenuItem.parentId = planMenuItem.id;
        menuItems.push(slaughterMenuItem);

        const slaughterRecordMenuItem = new TpiMenuItem();
        slaughterRecordMenuItem.id = 300004;
        slaughterRecordMenuItem.name = '出栏记录';
        slaughterRecordMenuItem.path = '/ranch/plan/slaughterRecord';
        slaughterRecordMenuItem.icon = 'chuku';
        slaughterRecordMenuItem.accessId = '101114';
        slaughterRecordMenuItem.parentId = planMenuItem.id;
        menuItems.push(slaughterRecordMenuItem);

        const inventoryMenuItem = new TpiMenuItem();
        inventoryMenuItem.id = 300005;
        inventoryMenuItem.name = '盘点管理';
        inventoryMenuItem.path = '/ranch/plan/inventory';
        inventoryMenuItem.icon = 'kucunpandian';
        inventoryMenuItem.accessId = '101112';
        inventoryMenuItem.parentId = planMenuItem.id;
        menuItems.push(inventoryMenuItem);

        return menuItems;
    }

}
