/**
 * Created by guanyj on  7/15/19
 */
import {Observable} from "rxjs/index";
import {URL_CONST} from "../../const/url-const";
import {Version} from "../../enum/version.enum";
import {Injectable} from "@angular/core";
import {DICTIONARY_CONST} from "../../const/dictionary.const";
import {RequestMsg, ResponseMsg, HttpService} from '@orchid_service/http';

@Injectable()
export class CommonRequestService {

    constructor(
        private $http: HttpService
    ) {}

    /**
     * 查询地区数据列表
     * @returns {Observable<ResponseMsg<Array<CityModel>>>}
     */
    public queryDistrictList(): Observable<ResponseMsg<Array<any>>> {
        const request = new RequestMsg();
        request.url = URL_CONST.common.distinct;
        request.version = Version.v1;

        return this.$http.post(request);
    }


    /**
     * 查询所有字典并进行缓存
     * @returns {Observable<ResponseMsg>}
     */
    public getDictionary() {
        const request = new RequestMsg();
        request.url = URL_CONST.common.dictionary;
        const dictTypes = Object.keys(DICTIONARY_CONST).map(key => DICTIONARY_CONST[key]).join(',');
        request.body = { dcitTypes: dictTypes };
        request.version = Version.v1;

        return this.$http.post(request);
    }

    /**
     * 上传附件
     * @returns {Observable<ResponseMsg>}
     */
    public bindAttach(params: any) {
        const request = new RequestMsg();
        request.url = URL_CONST.common.bindAttach;
        request.body = params;
        request.version = Version.v1;

        return this.$http.post(request);
    }

    /**
     * 删除附件
     * @param id
     * @returns {Observable<boolean>}
     */
    public deleteAttach(id: any) {
        const request = new RequestMsg();
        request.url = URL_CONST.common.deleteAttach;
        request.body = {id};
        request.version = Version.v1;

        return this.$http.post(request);
    }

    /**
     * 查询权限列表
     * @param {UserModel} user
     * @returns {Observable<ResponseMsg>}
     */
    public queryPermissionList(user: any): Observable<ResponseMsg> {
        const request = new RequestMsg();
        request.url = URL_CONST.common.permission;
        request.body = {
            memberId: user.memberId,
            organizationId: user.organizationId,
            // todo 去掉
            code: 'pms_pc'
        };
        request.version = Version.v1;

        return this.$http.post(request);
    }
}
