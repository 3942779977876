// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    production: false,
    appName: 'pms_pc',
    loginService: 'tpi.login',
    // mock数据接口
    // 如果以代理的方式运行项目,优先匹配此处配置。
    proxy: {
        // "/orchid-pms-mock": "https://www.easy-mock.com/mock/5d24036db491124e37cee77b"
        "/orchid-pms-mock": "https://www.fastmock.site/mock/7d4146a4cd30c790f795f15132bc909a/demo"
    }
};
